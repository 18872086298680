
<div #dataDrivenDropdown [class.my-dropdown--idle]="!showLoader && !loading" [class.my-dropdown--busy]="showLoader || loading" class="my-dropdown custom-select-container data-driven-dropdown" [class.is-multiselect]="multiselect" style="clear:both;">
  <div class="custom-select" [class.disabled]="disabled || isValidationPending || loading" [class.isValidationPending]="isValidationPending">

    <mibp-loader disabledBackgroundColor="true" type="spinner" style="align-items: center; height: calc(100% - 2px); background-color: #f8f8f8;"
    [style.display]="showLoader || loading ? 'flex' : 'none'"></mibp-loader>


    <span (mousedown)="focusFilterInput($event, tbx)">


      <span [ngClass]="{ 'multiselect-items': multiselect, 'single-select': !multiselect }">

        <ng-template [ngIf]="multiselect">
          <span class="selected-item is-placeholder" *ngIf="!hasSelectedItems && makePlaceholderLookLikeATag">
              <mibp-resource-string [key]="placeholder"></mibp-resource-string>
          </span>
          <ng-template [ngIf]="!newMultiSelectDesign">
            <span class="selected-item" *ngFor="let item of multiSelectedOption; trackBy: trackByDropdownInput" [class.is-invalid]="isOptionInvalid(item)">
              {{item.text}}
              <a href="javascript:void(0)"
                *ngIf="!(cannotRemoveLastItem && multiSelectedOption.length === 1)"
              class="remove-selected-item" (click)="removeSelectedOption($event, item, true)"><fa-icon [icon]="removeIcon"></fa-icon></a>
            </span>
          </ng-template>
          <ng-template [ngIf]="newMultiSelectDesign">
            <span *ngIf="multiSelectedOption && multiSelectedOption.length > 0">
              <span *ngIf="multiSelectedOption[0].text && !multiSelectedOption[0].resourceStringKey && !multiSelectedOption[0].htmlText">{{multiSelectedOption[0].text}}</span>
              <span *ngIf="multiSelectedOption[0].resourceStringKey && !multiSelectedOption[0].htmlText"><mibp-resource-string [key]="multiSelectedOption[0].resourceStringKey"></mibp-resource-string></span>
              <span *ngIf="multiSelectedOption[0].htmlText" [innerHTML]="multiSelectedOption[0].htmlText"></span>
              &nbsp;
              <span class="selected-count" *ngIf="multiSelectedOption.length > 1">
                +{{multiSelectedOption.length - 1}}
              </span>
            </span>
          </ng-template>
        </ng-template>

        <span class="input-field">
          <input type="text"
            autocomplete="off"
            mibpInputAutosize
            [mibpInputAutosize.disabled]="!!!multiselect"
            [class.has-selected-items]="hasSelectedItems"
            (keydown)="filterInputChange(tbx.value, $event)"
            (keyup)="filterInputChange(tbx.value, $event)"
            (change)="filterInputChange(tbx.value, $event)"
            #tbx (focus)="onFilterInputFocus()" style="z-index: 100; width: 100%;" (blur)="onFilterInputBlur()" />
            <span *ngIf="enableClearSingleSelect" [hidden]="!isItemSelected" [mibpResourceStringAttributes]="{title: 'Global_Clear'}" class="clear material-icon material-icon--strong" [icon]="clearSearchIcon" (click)="removeSelectedOptionSingleSelect($event)" >close</span>
          </span>
      </span>

      <span style="position: absolute; border-right: none; pointer-events: none;   text-overflow: ellipsis; left:0;
        width: 100%;
        padding-right: 38px;" [style.visibility]="showDropdownList ? 'hidden' : 'visible'">

      <!-- Not Multiselect? Then we place the selected item after the textbox -->
        <span *ngIf="!multiselect && hasSelectedItems && !singleSelectedOption.htmlText">{{singleSelectedOption?.text}}</span>
        <span *ngIf="!multiselect && hasSelectedItems && singleSelectedOption.htmlText" [innerHTML]="singleSelectedOption?.htmlText"></span>

        <mibp-resource-string *ngIf="!hasSelectedItems && !makePlaceholderLookLikeATag" [key]="placeholder"></mibp-resource-string>
      </span>
    </span>
  </div>
  <div [style.display]="showDropdownList ? 'block' : 'none'" #dropdownlist class="datadriven-items" (mousedown)="onDropdownListMouseDown($event)">
    <div [hidden]="!(showLoader || loading)" style="cursor: progress; position:absolute; box-sizing: content-box; background-color: rgba(255,255,255,0.5); width: 100%; height: 100%;">
      <p *ngIf="!visibleItems || visibleItems?.length === 0" style="padding: 4px;"><mibp-resource-string key="Global_WaitMessage"></mibp-resource-string></p>
    </div>
    <!-- <div style="padding: 4px;" [hidden]="!showLoader"><mibp-loader showSpinner="true"></mibp-loader> <mibp-resource-string key="Global_WaitMessage"></mibp-resource-string></div> -->
    <p class="no-items" [hidden]="visibleItems?.length !== 0 || (showLoader || loading)">No items</p>
    <div class="list" (scroll)="scrollHandler($event)" #listOfItems>
        <div *ngFor="let item of visibleItems; let ix = index; trackBy: trackByDropdownInput" class="datadriven-item" [class.disabledStatus]="item.disabled" [class.active]="item.value == singleSelectedOption?.value" (click)="selectItem(item, $event)">
          <ng-template [ngIf]="multiselect">
            <div class="checkbox">
              <input
                [disabled]="isSelected(item) && (cannotRemoveLastItem && multiSelectedOption?.length === 1)"
                [checked]="isSelected(item)" [attr.id]="'ddi' + ix" type="checkbox" />
              <label *ngIf="item.text && !item.resourceStringKey && !item.htmlText" [attr.for]="'ddi' + ix">{{ item.text }}<sup *ngIf="item.disabled">Inactive</sup><span style="margin-left: unset;" *ngIf="item.count == 0 || item.count > 0">&nbsp;({{item.count}})</span></label>
              <label *ngIf="item.resourceStringKey && !item.htmlText" [attr.for]="'ddi' + ix"><mibp-resource-string [key]="item.resourceStringKey"></mibp-resource-string><sup *ngIf="item.disabled">Inactive</sup><span style="margin-left: unset;" *ngIf="item.count == 0 || item.count > 0">&nbsp;({{item.count}})</span></label>
              <label *ngIf="item.htmlText" [innerHTML]="item.htmlText" [attr.for]="'ddi' + ix"><sup *ngIf="item.disabled">Inactive</sup><span style="margin-left: unset;" *ngIf="item.count == 0 || item.count > 0">&nbsp;({{item.count}})</span></label>
            </div>
          </ng-template>
          <ng-template [ngIf]="!multiselect && !item.htmlText" >
            {{item.text}}<sup *ngIf="item.disabled">Inactive</sup>
          </ng-template>
          <div *ngIf="!multiselect && item.htmlText" [innerHTML]="item.htmlText"></div>

        </div>
      </div>
    <div style="position:relative;background-color: #ffffca; color: #616161;     border-top: 1px solid #aaa; padding:4px;font-size: .85em;">
      <mibp-loader type='spinner' *ngIf="showLoader || loading"></mibp-loader>
      <div [hidden]="showLoader || loading">
        <span translate="no" class="material-icon material-icon--blue" style="margin-right: 4px" [hidden]="!footerDetails.hasMoreResults">arrow_downward</span>
        <strong [hidden]="showLoader || loading">{{footerDetails.visibleItemCount}}</strong>
        /
        <span [hidden]="showLoader || loading">{{footerDetails.count}}</span>
      </div>
    </div>
    <div class="actionbar" *ngIf="multiselectApply">

      <div>
        <a (click)="onApply($event, 'clear')" [class.disabled]="!hasSelectedItems" class="action action--clear">
          <span translate="no" class="material-icon">mop</span>
          <mibp-resource-string key="Global_Clear"></mibp-resource-string>
        </a>
      </div>

      <div>

      <a (click)="onApply($event, 'cancel')" class="action action--cancel">
        <span translate="no" class="material-icon">block</span>
        <mibp-resource-string key="Global_CancelButton"></mibp-resource-string>
      </a>

      <a (click)="onApply($event, 'apply')" class="action action--apply">
        <span translate="no" class="material-icon">done</span>
        <mibp-resource-string key="Global_Apply"></mibp-resource-string>
      </a>

    </div>

    </div>
  </div>
</div>
